<div class="ngx-toolbar" *ngIf="config['showToolbar']">
  <div class="ngx-toolbar-set">
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('bold')" (click)="triggerCommand('bold')"
      title="Bold" [disabled]="!config['enableToolbar']">
      <i class="fa fa-bold" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('italic')" (click)="triggerCommand('italic')"
      title="Italic" [disabled]="!config['enableToolbar']">
      <i class="fa fa-italic" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('underline')" (click)="triggerCommand('underline')"
      title="Underline" [disabled]="!config['enableToolbar']">
      <i class="fa fa-underline" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('strikeThrough')" (click)="triggerCommand('strikeThrough')"
      title="Strikethrough" [disabled]="!config['enableToolbar']">
      <i class="fa fa-strikethrough" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('superscript')" (click)="triggerCommand('superscript')"
      title="Superscript" [disabled]="!config['enableToolbar']">
      <i class="fa fa-superscript" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('subscript')" (click)="triggerCommand('subscript')"
      title="Subscript" [disabled]="!config['enableToolbar']">
      <i class="fa fa-subscript" aria-hidden="true"></i>
    </button>
  </div>
  <div class="ngx-toolbar-set">
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('fontName')" (click)="fontName = ''"
      title="Font Family" [popover]="fontNameTemplate" #fontNamePopover="bs-popover" containerClass="ngxePopover"
      [disabled]="!config['enableToolbar']">
      <i class="fa fa-font" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('fontSize')" (click)="fontSize = ''"
      title="Font Size" [popover]="fontSizeTemplate" #fontSizePopover="bs-popover" containerClass="ngxePopover"
      [disabled]="!config['enableToolbar']">
      <i class="fa fa-text-height" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('color')" (click)="hexColor = ''"
      title="Color Picker" [popover]="insertColorTemplate" #colorPopover="bs-popover" containerClass="ngxePopover"
      [disabled]="!config['enableToolbar']">
      <i class="fa fa-tint" aria-hidden="true"></i>
    </button>
  </div>
  <div class="ngx-toolbar-set">
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('justifyLeft')" (click)="triggerCommand('justifyLeft')"
      title="Justify Left" [disabled]="!config['enableToolbar']">
      <i class="fa fa-align-left" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('justifyCenter')" (click)="triggerCommand('justifyCenter')"
      title="Justify Center" [disabled]="!config['enableToolbar']">
      <i class="fa fa-align-center" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('justifyRight')" (click)="triggerCommand('justifyRight')"
      title="Justify Right" [disabled]="!config['enableToolbar']">
      <i class="fa fa-align-right" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('justifyFull')" (click)="triggerCommand('justifyFull')"
      title="Justify" [disabled]="!config['enableToolbar']">
      <i class="fa fa-align-justify" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('indent')" (click)="triggerCommand('indent')"
      title="Indent" [disabled]="!config['enableToolbar']">
      <i class="fa fa-indent" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('outdent')" (click)="triggerCommand('outdent')"
      title="Outdent" [disabled]="!config['enableToolbar']">
      <i class="fa fa-outdent" aria-hidden="true"></i>
    </button>
  </div>
  <div class="ngx-toolbar-set">
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('cut')" (click)="triggerCommand('cut')"
      title="Cut" [disabled]="!config['enableToolbar']">
      <i class="fa fa-scissors" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('copy')" (click)="triggerCommand('copy')"
      title="Copy" [disabled]="!config['enableToolbar']">
      <i class="fa fa-files-o" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('delete')" (click)="triggerCommand('delete')"
      title="Delete" [disabled]="!config['enableToolbar']">
      <i class="fa fa-trash" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('removeFormat')" (click)="triggerCommand('removeFormat')"
      title="Clear Formatting" [disabled]="!config['enableToolbar']">
      <i class="fa fa-eraser" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('undo')" (click)="triggerCommand('undo')"
      title="Undo" [disabled]="!config['enableToolbar']">
      <i class="fa fa-undo" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('redo')" (click)="triggerCommand('redo')"
      title="Redo" [disabled]="!config['enableToolbar']">
      <i class="fa fa-repeat" aria-hidden="true"></i>
    </button>
  </div>
  <div class="ngx-toolbar-set">
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('paragraph')" (click)="triggerCommand('insertParagraph')"
      title="Paragraph" [disabled]="!config['enableToolbar']">
      <i class="fa fa-paragraph" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('blockquote')" (click)="triggerCommand('blockquote')"
      title="Blockquote" [disabled]="!config['enableToolbar']">
      <i class="fa fa-quote-left" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('removeBlockquote')" (click)="triggerCommand('removeBlockquote')"
      title="Remove Blockquote" [disabled]="!config['enableToolbar']">
      <i class="fa fa-quote-right" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('horizontalLine')" (click)="triggerCommand('insertHorizontalRule')"
      title="Horizontal Line" [disabled]="!config['enableToolbar']">
      <i class="fa fa-minus" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('unorderedList')" (click)="triggerCommand('insertUnorderedList')"
      title="Unordered List" [disabled]="!config['enableToolbar']">
      <i class="fa fa-list-ul" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('orderedList')" (click)="triggerCommand('insertOrderedList')"
      title="Ordered List" [disabled]="!config['enableToolbar']">
      <i class="fa fa-list-ol" aria-hidden="true"></i>
    </button>
  </div>
  <div class="ngx-toolbar-set">
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('link')" (click)="buildUrlForm()"
      [popover]="insertLinkTemplate" title="Insert Link" #urlPopover="bs-popover" containerClass="ngxePopover"
      [disabled]="!config['enableToolbar']">
      <i class="fa fa-link" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('unlink')" (click)="triggerCommand('unlink')"
      title="Unlink" [disabled]="!config['enableToolbar']">
      <i class="fa fa-chain-broken" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('image')" (click)="buildImageForm()"
      title="Insert Image" [popover]="insertImageTemplate" #imagePopover="bs-popover" containerClass="ngxePopover"
      [disabled]="!config['enableToolbar']">
      <i class="fa fa-picture-o" aria-hidden="true"></i>
    </button>
    <button type="button" class="ngx-editor-button" *ngIf="canEnableToolbarOptions('video')" (click)="buildVideoForm()"
      title="Insert Video" [popover]="insertVideoTemplate" #videoPopover="bs-popover" containerClass="ngxePopover"
      [disabled]="!config['enableToolbar']">
      <i class="fa fa-youtube-play" aria-hidden="true"></i>
    </button>
  </div>
</div>

<!-- URL Popover template -->
<ng-template #insertLinkTemplate>
  <div class="ngxe-popover extra-gt">
    <form [formGroup]="urlForm" (ngSubmit)="urlForm.valid && insertLink()" autocomplete="off">
      <div class="form-group">
        <label for="urlInput" class="small">URL</label>
        <input type="text" class="form-control-sm" id="URLInput" placeholder="URL" formControlName="urlLink" required>
      </div>
      <div class="form-group">
        <label for="urlTextInput" class="small">Text</label>
        <input type="text" class="form-control-sm" id="urlTextInput" placeholder="Text" formControlName="urlText"
          required>
      </div>
      <div class="form-check">
        <input type="checkbox" class="form-check-input" id="urlNewTab" formControlName="urlNewTab">
        <label class="form-check-label" for="urlNewTab">Open in new tab</label>
      </div>
      <button type="submit" class="btn-primary btn-sm btn">Submit</button>
    </form>
  </div>
</ng-template>

<!-- Image Uploader Popover template -->
<ng-template #insertImageTemplate>
  <div class="ngxe-popover imgc-ctnr">
    <div class="imgc-topbar btn-ctnr">
      <button type="button" class="btn" [ngClass]="{active: isImageUploader}" (click)="isImageUploader = true">
        <i class="fa fa-upload"></i>
      </button>
      <button type="button" class="btn" [ngClass]="{active: !isImageUploader}" (click)="isImageUploader = false">
        <i class="fa fa-link"></i>
      </button>
    </div>
    <div class="imgc-ctnt is-image">
      <div *ngIf="isImageUploader; else insertImageLink"> </div>
      <div *ngIf="!isImageUploader; else imageUploder"> </div>
      <ng-template #imageUploder>
        <div class="ngx-insert-img-ph">
          <p *ngIf="uploadComplete">Choose Image</p>
          <p *ngIf="!uploadComplete">
            <span>Uploading Image</span>
            <br>
            <span>{{ updloadPercentage }} %</span>
          </p>
          <div class="ngxe-img-upl-frm">
            <input type="file" (change)="onFileChange($event)" accept="image/*" [disabled]="isUploading" [style.cursor]="isUploading ? 'not-allowed': 'allowed'">
          </div>
        </div>
      </ng-template>
      <ng-template #insertImageLink>
        <form class="extra-gt" [formGroup]="imageForm" (ngSubmit)="imageForm.valid && insertImage()" autocomplete="off">
          <div class="form-group">
            <label for="imageURLInput" class="small">URL</label>
            <input type="text" class="form-control-sm" id="imageURLInput" placeholder="URL" formControlName="imageUrl"
              required>
          </div>
          <button type="submit" class="btn-primary btn-sm btn">Submit</button>
        </form>
      </ng-template>
      <div class="progress" *ngIf="!uploadComplete">
        <div class="progress-bar progress-bar-striped progress-bar-animated bg-success" [ngClass]="{'bg-danger': updloadPercentage<20, 'bg-warning': updloadPercentage<50, 'bg-success': updloadPercentage>=100}"
          [style.width.%]="updloadPercentage"></div>
      </div>
    </div>
  </div>
</ng-template>


<!-- Insert Video Popover template -->
<ng-template #insertVideoTemplate>
  <div class="ngxe-popover imgc-ctnr">
    <div class="imgc-topbar btn-ctnr">
      <button type="button" class="btn active">
        <i class="fa fa-link"></i>
      </button>
    </div>
    <div class="imgc-ctnt is-image">
      <form class="extra-gt" [formGroup]="videoForm" (ngSubmit)="videoForm.valid && insertVideo()" autocomplete="off">
        <div class="form-group">
          <label for="videoURLInput" class="small">URL</label>
          <input type="text" class="form-control-sm" id="videoURLInput" placeholder="URL" formControlName="videoUrl"
            required>
        </div>
        <div class="row form-group">
          <div class="col">
            <input type="text" class="form-control-sm" formControlName="height" placeholder="height (px)" pattern="[0-9]">
          </div>
          <div class="col">
            <input type="text" class="form-control-sm" formControlName="width" placeholder="width (px)" pattern="[0-9]">
          </div>
          <label class="small">Height/Width</label>
        </div>
        <button type="submit" class="btn-primary btn-sm btn">Submit</button>
      </form>
    </div>
  </div>
</ng-template>

<!-- Insert color template -->
<ng-template #insertColorTemplate>
  <div class="ngxe-popover imgc-ctnr">
    <div class="imgc-topbar two-tabs">
      <span (click)="selectedColorTab ='textColor'" [ngClass]="{active: selectedColorTab ==='textColor'}">Text</span>
      <span (click)="selectedColorTab ='backgroundColor'" [ngClass]="{active: selectedColorTab ==='backgroundColor'}">Background</span>
    </div>
    <div class="imgc-ctnt is-color extra-gt1">
      <form autocomplete="off">
        <div class="form-group">
          <label for="hexInput" class="small">Hex Color</label>
          <input type="text" class="form-control-sm" id="hexInput" name="hexInput" maxlength="7" placeholder="HEX Color"
            [(ngModel)]="hexColor" required>
        </div>
        <button type="button" class="btn-primary btn-sm btn" (click)="insertColor(hexColor, selectedColorTab)">Submit</button>
      </form>
    </div>
  </div>
</ng-template>

<!-- font size template -->
<ng-template #fontSizeTemplate>
  <div class="ngxe-popover extra-gt1">
    <form autocomplete="off">
      <div class="form-group">
        <label for="fontSize" class="small">Font Size</label>
        <input type="text" class="form-control-sm" id="fontSize" name="fontSize" placeholder="Font size in px/rem"
          [(ngModel)]="fontSize" required>
      </div>
      <button type="button" class="btn-primary btn-sm btn" (click)="setFontSize(fontSize)">Submit</button>
    </form>
  </div>
</ng-template>

<!-- font family/name template -->
<ng-template #fontNameTemplate>
  <div class="ngxe-popover extra-gt1">
    <form autocomplete="off">
      <div class="form-group">
        <label for="fontSize" class="small">Font Size</label>
        <input type="text" class="form-control-sm" id="fontSize" name="fontName" placeholder="Ex: 'Times New Roman', Times, serif"
          [(ngModel)]="fontName" required>
      </div>
      <button type="button" class="btn-primary btn-sm btn" (click)="setFontName(fontName)">Submit</button>
    </form>
  </div>
</ng-template>
